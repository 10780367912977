<template>
  <div id="student-attendance-info">
    <div class="columns is-multiline">
      <div class="column is-8 is-offset-2 month-panel">
        <p class="title is-4 has-text-right">
          {{ studentName }}
        </p>
        <b-tabs v-model="activeTab" v-if="months.length > 0">
          <b-tab-item
            v-for="(month, index) in months"
            :label="getMonth(month)"
            :key="month"
            @click="activeTab = index"
          >
            <div
              class="columns is-mobile is-multiline"
              v-for="(items, index) in attendances[month]"
              :key="index"
            >
              <div
                class="column is-3-mobile is-2-tablet"
                v-for="(item, i) in items"
                :key="i"
              >
                <b-checkbox v-model="item.value">
                  {{ item.day }}
                </b-checkbox>
              </div>
            </div>
          </b-tab-item>
        </b-tabs>
      </div>
      <div class="column is-8 is-offset-2">
        <div class="buttons is-right">
          <button class="button is-success" @click="takeAttendance()">
            Submit
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import { crudNotification } from '../../assets/js/notification'
import { fetchTerm } from '@/assets/js/app_info.js'

export default {
  name: 'SchoolClassAttendanceInfo',
  data() {
    return {
      schoolId: null,
      pageTitle: 'Student Attendance Info',
      attendances: {},
      student: [],
      studentId: null,
      studentName: null,
      schoolClassId: null,
      schoolClassName: null,
      month: null,
      activeTab: null,
      months: [],
      monthsOfTheYear: {
        1: 'January',
        2: 'February',
        3: 'March',
        4: 'April',
        5: 'May',
        6: 'June',
        7: 'July',
        8: 'August',
        9: 'September',
        10: 'October',
        11: 'November',
        12: 'December',
      },
      termId: null,
    }
  },
  watch: {
    student(data) {
      this.attendances = {}

      this.studentName = data.name

      this.months = data.termAttendance.term.daysInMonths.map(
        (item) => item.month
      )

      const month = Object.keys(this.monthsOfTheYear).filter((key) => {
        return this.monthsOfTheYear[key] === this.month
      })[0]

      this.activeTab = this.months.indexOf(month)

      Object.keys(data.termAttendance.records).forEach((month) => {
        const days = data.termAttendance.term.daysInMonths.filter(
          (item) => item.month === month
        )[0].days

        const days_in_school = { days: [], total: 0 }

        days.forEach((day) => {
          let record = data.termAttendance.records[month]

          let [day_record] = record.days.filter((item) => item.day == day)

          if (record.days.length != 0 && day_record.value) {
            days_in_school.days.push({
              day: `${day}`,
              value: true,
            })
          } else {
            days_in_school.days.push({
              day: `${day}`,
              value: false,
            })
          }
        })

        this.attendances[month] = days_in_school
      })
    },
  },
  mounted() {
    this.schoolId = parseInt(this.$route.params.school_id)
    this.studentId = parseInt(this.$route.params.id)
    this.schoolClassId = parseInt(this.$route.params.school_class_id)
    this.month = this.$route.params.month
    this.$store.commit('setPageTitle', this.pageTitle)
    this.$store.commit('setSubMenus', [
      {
        name: 'Attendances',
        route: `/school/${this.schoolId}/attendances`,
      },
      {
        name: 'School Class Attendance Info',
        route: `/school/${this.schoolId}/school_class_attendance_info/${this.schoolClassId}/month/${this.month}`,
      },
    ])

    fetchTerm().then((term) => {
      this.termId = term.id
    })

    this.$apollo.addSmartQuery('student', {
      query: gql`
        query StudentAttendanceQuery($id: ID!) {
          student(id: $id) {
            id
            name
            schoolClass {
              name
            }
            termAttendance {
              id
              term {
                id
                title
                daysInMonths
              }
              records
              total
            }
          }
        }
      `,
      variables: {
        id: this.studentId,
      },
    })

    this.$apollo.queries.student.refetch()
  },
  methods: {
    initializeInfo(studentId) {
      this.$router.push(
        `/school/${this.schoolId}/student_attendance_info/${studentId}`
      )
    },
    getMonth(index) {
      return this.monthsOfTheYear[index]
    },
    takeAttendance() {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation takeAttendanceQuery(
              $studentId: Int!
              $records: JSON!
              $termId: ID!
            ) {
              takeAttendance(
                input: {
                  studentId: $studentId
                  records: $records
                  termId: $termId
                }
              ) {
                student {
                  id
                }
                errors
              }
            }
          `,
          variables: {
            studentId: parseInt(this.studentId),
            records: JSON.parse(JSON.stringify(this.attendances)),
            termId: parseInt(this.termId),
          },
        })
        .then((response) => {
          crudNotification(
            response.data.takeAttendance.errors,
            'Successfully updated.'
          ).then(() => {
            this.$router.push(
              `/school/${this.schoolId}/school_class_attendance_info/${this.schoolClassId}/month/${this.month}`
            )
          })
        })
        .catch((error) => {
          console.error(error)
        })
    },
  },
}
</script>

<style lang="scss">
.month-panel {
  min-height: 350px;
}
@media only screen and (max-width: 768px) {
  .month-panel {
    min-height: 450px;
  }
}
</style>
